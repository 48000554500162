import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

import { ApiService, BootstrapData } from '../openapi'

@Injectable()
export class BootstrapService {
  constructor(
    private api: ApiService,
    ){ }

  get(): Observable<BootstrapData> {
    return this.api.bootstrapApiBootstrapGet()
  }
}
