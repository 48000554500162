<div layout="row" layout-padding fxLayout='column wrap' fxLayoutAlign="space-around center">
    <h1>Login</h1>
    <form fxFlex fxLayout='column wrap'>
        <div class="form-group" [formGroup]="formGroup" fxLayout='column wrap'>
            <mat-form-field>
                <mat-label>User name</mat-label>
                <input matInput type="text" formControlName="userName" #userName required/>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" #password required/>
            </mat-form-field>
        </div>
        <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formGroup.valid"
            (click)="submit()"
        >Login</button>
        <div *ngIf="error" class="alert alert-danger error">{{error}}</div>
    </form>
</div>