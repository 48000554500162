import { inject, Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router"
import { Subject, ReplaySubject, BehaviorSubject, Observable, map } from "rxjs"

import { BootstrapData } from "../openapi"
import { BootstrapService } from "./bootstrap.service"

export class Config {
    constructor(
        public bsData?: BootstrapData
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    // defaultConf: Config = {
    //     'map': {},
    //     'proj': {},
    //     'measures': {},
    //     'geo': {}
    // }

    hasConf = new ReplaySubject<undefined>()
    public conf: BehaviorSubject<Config> = new BehaviorSubject<Config>(new Config())

    /*
    bootstrap() {
        return this.httpClient.get('/v1/bootstrap').subscribe(
            resp => {
                this.conf.value.server = resp['server']
                this.conf.value.client = resp['client']
                this.conf.value.app = resp['app']
                this.conf.next(this.conf.value)
            }
        )
    }
    */

    setConf(bsData: BootstrapData) {
        this.conf.next(new Config(bsData))
        // this.conf.value.map = c.
        // this.conf.value.proj = c['proj']
        // this.conf.value.geo = c['geo']
        // this.conf.value.measures = c['measures']
        // this.conf.next(this.conf.value)
        localStorage.setItem('bsData', JSON.stringify(bsData))
        this.hasConf.next(undefined)
    }
}

export const configResolver: ResolveFn<Observable<Config>> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        let configService = inject(ConfigService)
        return configService.hasConf.pipe(map(
            _ => configService.conf.value
        ))
    }