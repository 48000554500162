import {
    Component, OnInit, ViewChild, ElementRef,
    ChangeDetectorRef, ChangeDetectionStrategy, Inject
} from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'

import { ConfigService } from '../_services/config.service'
import { AuthenticationService } from '../_services/authentication.service'

export interface LoginDialogData {
    username: string;
    password: string;
}

@Component({
    selector: 'gisaf-login-dialog',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false,
})
export class LoginDialogComponent implements OnInit {
    error = ''

    formGroup: UntypedFormGroup
    @ViewChild('userName', { static: true }) userName: ElementRef

    constructor(
        public dialogRef: MatDialogRef<LoginDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LoginDialogData,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.userName.nativeElement.focus()
        this.formGroup = new UntypedFormGroup({
            userName: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', Validators.required),
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    submit() {
        this.authenticationService.login(this.formGroup.value.userName, this.formGroup.value.password).subscribe({
            next: result => {
                console.log('Login successful')
                // this.router.navigate(['/'])
                this.dialogRef.close()
                this.cdr.markForCheck()
            },
            error: error => {
                console.error(error)
                this.error = error.statusText
                this.cdr.markForCheck()
            }
        })
    }
}