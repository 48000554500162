import { Component, OnInit, Inject } from '@angular/core'
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
    selector: 'gisaf-html-snackbar',
    templateUrl: './custom-snackbar.component.html',
    styleUrls: ['./custom-snackbar.component.scss']
})
export class HtmlSnackbarComponent {
constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar
    ) {}
}