import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'

import { FlexLayoutModule } from 'ngx-flexible-layout'

import { AppComponent } from './app.component'
import { PageNotFoundComponent } from './pageNotFound.component'
import { LoginModule } from './login/login.module'
import { AuthenticationService } from './_services/authentication.service'
import { BootstrapService } from './_services/bootstrap.service'
import { ModelDataService } from './_services/apollo.service'
import { ActionsService } from './_services/actions.service'
import { ConfigService } from './_services/config.service'

import { ApiService } from './openapi/services'
import { AdminService } from './openapi/services'
import { DashboardService } from './openapi/services'
import { GeoapiService } from './openapi/services'
import { MapService } from './openapi/services'
import { httpInterceptorProviders } from './_helpers/http.interceptor'

import { HtmlSnackbarComponent } from './custom-snackbar/custom-snackbar.component'

import { AppRoutingModule } from './app-routing.module'

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HtmlSnackbarComponent,
    ],
    imports: [
        // ApolloModule,

        BrowserModule,
        BrowserAnimationsModule,

        FormsModule,
        HttpClientModule,

        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        MatTooltipModule,

        FlexLayoutModule,

        AppRoutingModule,
        LoginModule,
    ],
    providers: [
        ActionsService,
        AuthenticationService,
        BootstrapService,
        ApiService,
        AdminService,
        GeoapiService,
        DashboardService,
        MapService,
        ConfigService,
        ModelDataService,
        httpInterceptorProviders,
        {
            provide: LOCALE_ID,
            useValue: "en-IN"
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}