<div class='container'>
    <div class='message'>{{ data['message']}}</div>
    <div class='data'>
        <table>
            <tr *ngFor="let row of data['details'] | keyvalue">
                <th>{{ row.key }}</th>
                <td>{{ row.value }}</td>
            </tr>
        </table>
    </div>
    <button class='button' mat-button (click)="snackBar.dismiss()">Close</button>
</div>