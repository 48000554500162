import { Component, OnInit,
    ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { BootstrapService } from './_services/bootstrap.service'
import { ConfigService } from './_services/config.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

import { AuthenticationService } from './_services/authentication.service'
import { LoginDialogComponent } from './login/login.component'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    title = 'Gisaf'
    version: string

    routes = [
        {
            'target': 'dashboard',
            'icon': 'home',
            'text': 'Home',
        },
        {
            'target': 'map',
            'icon': 'map',
            'text': 'Map',
        },
        {
            'target': 'measures',
            'icon': 'insert_chart',
            'text': 'Measures',
        },
    ]

    constructor(
        public configService: ConfigService,
        private titleService: Title,
        private bootstrapService: BootstrapService,
        public authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        public dialogRef: MatDialogRef<LoginDialogComponent>,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        // Bootstrap: set app wide configuration
        this.bootstrapService.get().subscribe({
            next: res => {
                this.version = res.version
                this.title = res.title || this.title
                this.titleService.setTitle(res.windowTitle || this.title)
                this.configService.setConf(res)
                if (res.redirect && (window != window.top)) {
                    // Refusing to be embedded in an iframe
                    let loc = res.redirect + window.location.pathname
                    window.document.body.innerHTML = `
                    The web site you are visiting is trying to embed Gisaf (${this.title}) in an iFrame, it isn't a nice thing to do.</br>
                    Please click this link to go to the real site: <span style='cursor:pointer' onclick="window.open('${loc}')"><b>${loc}</b></span>
                    `
                }
            },
            error: err => {
                this.snackBar.open(
                    'Cannot connect to the server (' + err.statusText + '). Please retry later.',
                    'OK'
                )
            }
        })
    }

    openLoginDialog() {
        const dialogRef = this.dialog.open(LoginDialogComponent, {
            height: '24em',
            width: '21em'
        })

        // dialogRef.afterClosed().subscribe(
        //     result => {}
        // )
    }
}
