<div fxLayout="column" fxLayoutAlign="stretch" fxFlexFill>
    <mat-toolbar fxFlex="2em" id='top-toolbar'>
        <span
            style='font-family:GisafSymbols'
            matTooltip="Gisaf v. {{ (configService.conf | async).bsData?.version }}"
            matTooltipPosition="below"
            class='gisafIcon'
            >
            &#xE001;
        </span>
        <a id='title' routerLink="/admin"
            matTooltip='Go to admin'
            routerLinkActive="active"
        >
            {{ title }}
        </a>
        <span class="fill-space"></span>
        <nav>
            <a mat-raised-button
                *ngFor="let route of routes"
                [routerLink]="route.target"
                routerLinkActive="active">
                <mat-icon>{{ route.icon }}</mat-icon>
                {{ route.text }}
            </a>
        </nav>
        <span class="fill-space"></span>
        <span *ngIf="(configService.conf | async).bsData?.user; else login" class="auth-indicator">
            <nav>
                <a mat-icon-button
                    (click)='this.authenticationService.logout()'
                    aria-label="Log out"
                    matTooltip="User: {{ (configService.conf | async ).bsData?.user.username }}. Click to log out">
                    <mat-icon>verified_user</mat-icon>
                </a>
            </nav>
        </span>
    </mat-toolbar>
    <div fxFlex>
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #login>
    <nav>
        <a mat-icon-button (click)="openLoginDialog()" matTooltip="Log in">
            <mat-icon>account_circle</mat-icon>
        </a>
    </nav>
</ng-template>