import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { configResolver } from './_services/config.service'
import { LoginDialogComponent } from './login/login.component'
import { PageNotFoundComponent } from './pageNotFound.component'

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'map', loadChildren: () => import('./map/map.module').then(m => m.MapModule), resolve: {conf: configResolver}, },
    { path: 'measures', loadChildren: () => import('./measures/measures.module').then(m => m.MeasuresModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: PageNotFoundComponent }
]

@NgModule({
    imports: [ RouterModule.forRoot(routes, {
    enableTracing: false
})],
    exports: [ RouterModule ]
})

export class AppRoutingModule {}
