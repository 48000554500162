import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FormsModule, ReactiveFormsModule }   from '@angular/forms'

import { FlexLayoutModule } from 'ngx-flexible-layout'

import { LoginDialogComponent } from './login.component'
import { MatDialogRef } from '@angular/material/dialog'

@NgModule({
    imports: [
        CommonModule,

        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,

        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
    ],
    declarations: [
        LoginDialogComponent,
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {}
        },
    ]
})

export class LoginModule {}
