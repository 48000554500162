import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { OpenAPI } from './core/OpenAPI';
import { request as __request } from './core/request';

import type { ActionsResults,ActionsStore,Body_execute_tag_action_api_execTagActions_post,Body_login_for_access_token_api_token_post,BootstrapData,CategoryRead,DataProvider,FeatureInfo,MeasuresItem,ModelInfo,PlotParams,Project,RoleRead,Store,SurveyMeta,Token,UserRead,UserRoleLink,AdminBasket,BasketImportResult,BasketNameOnly,Body_upload_basket_file_api_admin_basket_upload__name__post,Dashboard,DashboardGroup,DashboardHome,BaseStyle,MapInitData,MaplibreStyle } from './models';

export type TDataLoginForAccessTokenApiTokenPost = {
                formData: Body_login_for_access_token_api_token_post
                
            }
export type TDataGetModelListApiDataProviderStoreGet = {
                store: string
                
            }
export type TDataGetModelValuesApiStoreNameValuesValueGet = {
                resample?: string | null
storeName: string
value: string
where: string
                
            }
export type TDataGetFeatureInfoApiFeatureInfoStoreIdGet = {
                id: string
store: string
                
            }
export type TDataGetModelInfoApiModelInfoStoreGet = {
                store: string
                
            }
export type TDataGetPlotParamsApiPlotParamsStoreGet = {
                id: string
store: string
value: string
                
            }
export type TDataExecuteTagActionApiExecTagActionsPost = {
                requestBody: Body_execute_tag_action_api_execTagActions_post
                
            }

@Injectable({
  providedIn: 'root',
})
export class ApiService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Bootstrap
	 * @returns BootstrapData Successful Response
	 * @throws ApiError
	 */
	public bootstrapApiBootstrapGet(): Observable<BootstrapData> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/bootstrap',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Login For Access Token
	 * @returns Token Successful Response
	 * @throws ApiError
	 */
	public loginForAccessTokenApiTokenPost(data: TDataLoginForAccessTokenApiTokenPost): Observable<Token> {
		const {
formData,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'POST',
			url: '/api/token',
			formData: formData,
			mediaType: 'application/x-www-form-urlencoded',
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Logout
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public logoutApiLogoutGet(): Observable<unknown> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/logout',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Users
	 * @returns UserRead Successful Response
	 * @throws ApiError
	 */
	public getUsersApiUsersGet(): Observable<Array<UserRead>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/users',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Roles
	 * @returns RoleRead Successful Response
	 * @throws ApiError
	 */
	public getRolesApiRolesGet(): Observable<Array<RoleRead>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/roles',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Acls
	 * New: ACLs returned as UserRoleLink
	 * @returns UserRoleLink Successful Response
	 * @throws ApiError
	 */
	public getAclsApiAclsGet(): Observable<Array<UserRoleLink>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/acls',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Categories
	 * @returns CategoryRead Successful Response
	 * @throws ApiError
	 */
	public getCategoriesApiCategoriesGet(): Observable<Array<CategoryRead>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/categories',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Categories P
	 * @returns CategoryRead Successful Response
	 * @throws ApiError
	 */
	public getCategoriesPApiCategoriesPandasGet(): Observable<Array<CategoryRead>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/categories_pandas',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * List Data Providers
	 * Return a list of data providers, for use with the api (graphs, etc)
 * :return:
	 * @returns DataProvider Successful Response
	 * @throws ApiError
	 */
	public listDataProvidersApiDataProvidersGet(): Observable<Array<DataProvider>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/data-providers',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Model List
	 * Json REST store API compatible with Flask Potion and Angular
 * Get the list of items (used for making the list of items in measures)
 * Filter only items with at least one measure
	 * @returns MeasuresItem Successful Response
	 * @throws ApiError
	 */
	public getModelListApiDataProviderStoreGet(data: TDataGetModelListApiDataProviderStoreGet): Observable<Array<MeasuresItem>> {
		const {
store,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/data-provider/{store}',
			path: {
				store
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Model Values
	 * Get values
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public getModelValuesApiStoreNameValuesValueGet(data: TDataGetModelValuesApiStoreNameValuesValueGet): Observable<unknown> {
		const {
resample,
storeName,
value,
where,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/{store_name}/values/{value}',
			path: {
				store_name: storeName, value
			},
			query: {
				where, resample
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Stores
	 * @returns Store Successful Response
	 * @throws ApiError
	 */
	public getStoresApiStoresGet(): Observable<Array<Store>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/stores',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Projects
	 * @returns Project Successful Response
	 * @throws ApiError
	 */
	public getProjectsApiProjectsGet(): Observable<Array<Project>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/projects',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Survey Meta
	 * @returns SurveyMeta Successful Response
	 * @throws ApiError
	 */
	public getSurveyMetaApiSurveyMetaGet(): Observable<SurveyMeta> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/survey_meta',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Feature Info
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public getFeatureInfoApiFeatureInfoStoreIdGet(data: TDataGetFeatureInfoApiFeatureInfoStoreIdGet): Observable<FeatureInfo | null> {
		const {
id,
store,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/feature-info/{store}/{id}',
			path: {
				store, id
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Model Info
	 * @returns ModelInfo Successful Response
	 * @throws ApiError
	 */
	public getModelInfoApiModelInfoStoreGet(data: TDataGetModelInfoApiModelInfoStoreGet): Observable<ModelInfo> {
		const {
store,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/model-info/{store}',
			path: {
				store
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Plot Params
	 * @returns PlotParams Successful Response
	 * @throws ApiError
	 */
	public getPlotParamsApiPlotParamsStoreGet(data: TDataGetPlotParamsApiPlotParamsStoreGet): Observable<PlotParams> {
		const {
id,
store,
value,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/plot-params/{store}',
			path: {
				store
			},
			query: {
				id, value
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Actions
	 * @returns ActionsStore Successful Response
	 * @throws ApiError
	 */
	public getActionsApiActionsGet(): Observable<Array<ActionsStore>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/actions',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Execute Tag Action
	 * @returns ActionsResults Successful Response
	 * @throws ApiError
	 */
	public executeTagActionApiExecTagActionsPost(data: TDataExecuteTagActionApiExecTagActionsPost): Observable<ActionsResults> {
		const {
requestBody,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'POST',
			url: '/api/execTagActions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

}

export type TDataGetGeojsonApiGjStoreNameGet = {
                ifNoneMatch?: string | null
preserveTopology?: boolean | null
simplify?: number | null
storeName: unknown
                
            }

@Injectable({
  providedIn: 'root',
})
export class GeoapiService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Get Geojson
	 * Some REST stores coded manually (route prefixed with "gj": geojson).
 * :param store_name: name of the model
 * :return: json
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public getGeojsonApiGjStoreNameGet(data: TDataGetGeojsonApiGjStoreNameGet): Observable<unknown> {
		const {
ifNoneMatch,
preserveTopology,
simplify,
storeName,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/gj/{store_name}',
			path: {
				store_name: storeName
			},
			headers: {
				'If-None-Match': ifNoneMatch, simplify, preserveTopology
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

}

export type TDataGetBasketApiAdminBasketNameGet = {
                name: string
                
            }
export type TDataUploadBasketFileApiAdminBasketUploadNamePost = {
                formData: Body_upload_basket_file_api_admin_basket_upload__name__post
name: string
                
            }
export type TDataDownloadBasketFileApiAdminBasketDownloadNameFileIdFileNameGet = {
                fileId: number
fileName: string
name: string
                
            }
export type TDataImportBasketFileApiAdminBasketImportBasketFileIdGet = {
                basket: string
dryRun?: boolean
fileId: number
                
            }

@Injectable({
  providedIn: 'root',
})
export class AdminService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Get Baskets
	 * @returns BasketNameOnly Successful Response
	 * @throws ApiError
	 */
	public getBasketsApiAdminBasketGet(): Observable<Array<BasketNameOnly>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/admin/basket',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Basket
	 * @returns AdminBasket Successful Response
	 * @throws ApiError
	 */
	public getBasketApiAdminBasketNameGet(data: TDataGetBasketApiAdminBasketNameGet): Observable<AdminBasket> {
		const {
name,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/admin/basket/{name}',
			path: {
				name
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Upload Basket File
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public uploadBasketFileApiAdminBasketUploadNamePost(data: TDataUploadBasketFileApiAdminBasketUploadNamePost): Observable<unknown> {
		const {
formData,
name,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'POST',
			url: '/api/admin/basket/upload/{name}',
			path: {
				name
			},
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Download Basket File
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public downloadBasketFileApiAdminBasketDownloadNameFileIdFileNameGet(data: TDataDownloadBasketFileApiAdminBasketDownloadNameFileIdFileNameGet): Observable<unknown> {
		const {
fileId,
fileName,
name,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/admin/basket/download/{name}/{file_id}/{file_name}',
			path: {
				name, file_id: fileId, file_name: fileName
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Import Basket File
	 * @returns BasketImportResult Successful Response
	 * @throws ApiError
	 */
	public importBasketFileApiAdminBasketImportBasketFileIdGet(data: TDataImportBasketFileApiAdminBasketImportBasketFileIdGet): Observable<BasketImportResult> {
		const {
basket,
dryRun = false,
fileId,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/admin/basket/import/{basket}/{file_id}',
			path: {
				basket, file_id: fileId
			},
			query: {
				dryRun
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

}

export type TDataGetDashboardPageApiDashboardPageGroupNameGet = {
                group: string
name: string
                
            }

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Get Groups
	 * @returns DashboardGroup Successful Response
	 * @throws ApiError
	 */
	public getGroupsApiDashboardGroupsGet(): Observable<Array<DashboardGroup>> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/dashboard/groups',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Home
	 * @returns DashboardHome Successful Response
	 * @throws ApiError
	 */
	public getHomeApiDashboardHomeGet(): Observable<DashboardHome> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/dashboard/home',
			errors: {
				404: `Not found`,
			},
		});
	}

	/**
	 * Get Dashboard Page
	 * @returns Dashboard Successful Response
	 * @throws ApiError
	 */
	public getDashboardPageApiDashboardPageGroupNameGet(data: TDataGetDashboardPageApiDashboardPageGroupNameGet): Observable<Dashboard> {
		const {
group,
name,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/dashboard/page/{group}/{name}',
			path: {
				group, name
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

}

export type TDataGetBaseStyleApiMapBaseStyleNameGet = {
                name: string
                
            }
export type TDataGetLayerStyleApiMapLayerStyleStoreGet = {
                store: string
                
            }

@Injectable({
  providedIn: 'root',
})
export class MapService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Get Init Data
	 * @returns MapInitData Successful Response
	 * @throws ApiError
	 */
	public getInitDataApiMapInitDataGet(): Observable<MapInitData> {
				return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/map/init-data',
		});
	}

	/**
	 * Get Base Style
	 * @returns BaseStyle Successful Response
	 * @throws ApiError
	 */
	public getBaseStyleApiMapBaseStyleNameGet(data: TDataGetBaseStyleApiMapBaseStyleNameGet): Observable<BaseStyle> {
		const {
name,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/map/base_style/{name}',
			path: {
				name
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Get Layer Style
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public getLayerStyleApiMapLayerStyleStoreGet(data: TDataGetLayerStyleApiMapLayerStyleStoreGet): Observable<MaplibreStyle | null> {
		const {
store,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/map/layer_style/{store}',
			path: {
				store
			},
			errors: {
				422: `Validation Error`,
			},
		});
	}

}

export type TDataDownloadCsvApiDownloadCsvStoreModelIdValueResampleGet = {
                modelId: number
resample: string
store: string
value: string
                
            }
export type TDataExecuteActionApiDownloadPluginNameStoreIdGet = {
                id: number
name: string
store: string
                
            }

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

	constructor(public readonly http: HttpClient) {}

	/**
	 * Download Csv
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public downloadCsvApiDownloadCsvStoreModelIdValueResampleGet(data: TDataDownloadCsvApiDownloadCsvStoreModelIdValueResampleGet): Observable<unknown> {
		const {
modelId,
resample,
store,
value,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/download/csv/{store}/{model_id}/{value}/{resample}',
			path: {
				store, model_id: modelId, value, resample
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

	/**
	 * Execute Action
	 * Download the result of an action
	 * @returns unknown Successful Response
	 * @throws ApiError
	 */
	public executeActionApiDownloadPluginNameStoreIdGet(data: TDataExecuteActionApiDownloadPluginNameStoreIdGet): Observable<unknown> {
		const {
id,
name,
store,
} = data;
		return __request(OpenAPI, this.http, {
			method: 'GET',
			url: '/api/download/plugin/{name}/{store}/{id}',
			path: {
				name, store, id
			},
			errors: {
				404: `Not found`,
				422: `Validation Error`,
			},
		});
	}

}